const locale = {
  /** General */
  category: 'kategori',
  settings: 'pengaturan',
  pause: 'jeda',
  delete: 'hapus',
  save: 'simpan',
  cancel: 'batalkan',
  confirm: 'konfirmasi',

  /** Torrent */
  torrent: {
    title: 'judul',
    added: 'ditambahkan pada',
    availability: 'ketersediaan',
    size: 'ukuran',
    progress: 'progres',
    directory: 'direktori',
    downloaded: 'didownload',
    uploaded: 'diupload',
    created: 'dibuat oleh',
    comments: 'komentar'
  },
  /** Navbar */
  navbar: {
    currentSpeed: 'kecepatan sekarang',
    freeSpace: 'ruang bebas',
    topActions: {
      addTorrent: 'tambah torrent',
      resumeSelected: 'lanjutkan torrent yang dipilih',
      pauseSelected: 'jeda torrent yang dipilih',
      removeSelected: 'hapus torrent yang dipilih',
      openSettings: 'buka pengaturan',
      searchNew: 'cari torrent baru'
    },
    sessionStats: {
      tooltip: 'Sejak terakhir kali qBittorrent dijalankan ulang.'
    }
  },

  /** Modals */
  modals: {
    add: {
      title: 'Tambahkan Torrent baru',
      selectFiles: 'Pilih berkas anda'
    },
    delete: {
      check: 'Dan hapus berkas dari penyimpanan'
    }
  },

  /** Toast */
  toast: {
    loginSuccess: 'Berhasil masuk! 🎉',
    loginFailed: 'Gagal masuk 😕',
    settingsSaved: 'Pengaturan sukses disimpan!',
    categorySaved: 'Kategori sukses diubah!'
  },

  /** RightClick **/
  rightClick: {
    resume: 'lanjutkan',
    forceResume: 'paksa lanjutkan',
    advanced: {
      advanced: 'lebih lanjut',
      changeLocation: 'ubah lokasi',
      rename: 'ubah nama'
    },
    prio: {
      prio: 'tetapkan prioritas',
      top: 'atas',
      bottom: 'bawah',
      increase: 'tingkatkan',
      decrease: 'turunkan'
    },
    category: 'tetapkan kategori',
    limit: 'tetapkan limit',
    copy: 'salin',
    info: 'tampilkan info'
  }
}
  
export default locale
